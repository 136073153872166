import React from 'react';
import ReactDOM from 'react-dom';
import styled from "styled-components"
import './index.css';
import App from './App';

// Catch url params
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const id = urlParams.get("id")

// Available urls
// ?id=615571799c048c0a23f4db11

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

// If params are there we render approval app.
if (id) {
  ReactDOM.render(
    <React.StrictMode>
      <App paxID={id} />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Wrapper>
        Vous devez suivre le lien dans l'email que vous avez reçu !
      </Wrapper>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

