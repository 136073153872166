import { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { HList, VList, Card, Button, Title } from "@thetreep/components-library"
// import Spinner from "../components/atoms/spinner"
import CardBody from "./card.body"
import CardHeader from "./card.header"

const Wrapper = styled.div`
    display: flex;
    width: 450px;
`

const Form = ({ paxID }) => {
    const [ pax, setPax ] = useState({error: {hasError: false, message: ""}, syncing: false, completed: false, data: {attributes:{name: "", address: ""}, id:"" }})
    const [ posted, setPosted ] = useState({error: {hasError: false, message: ""}, syncing: false, completed: false})

    useEffect( () => {
        const fetch = async () => {
            setPax({error: {hasError: false, message: ""}, syncing: true, completed: false, data: {attributes:{name: "", address: ""}, id:"" }})
            try{
                const result = await axios.get(`https://trm-thetreep.herokuapp.com/paxs/${paxID}`)
                setPax({error: {hasError: false, message: ""}, syncing: false, completed: true, data: { ...result.data }})
            } catch(err) {
                setPax({error: {hasError: true, message: "Une erreur est survenue"}, syncing: false, completed: false, data: {attributes:{name: "", address: ""}, id:"" }})
            }
        }
        fetch()
    }, [ paxID ])

    const postForm = async (answer) => {
        setPosted({ ...posted, syncing: true, completed: false })
        try{
            await axios.put(`https://trm-thetreep.herokuapp.com/paxs/${paxID}`, {
                ...pax.data,
                status: answer,
            })
            setPosted({ ...posted, syncing: false, completed: true })
        } catch(err) {
            setPosted({ ...posted, syncing: false, error: { hasError: true, message: err} })
        }
    }

    return(
        <Wrapper>
            <Card overflow="unset">
                <VList h="fit-content" w="100%">
                    <CardHeader>
                        <Title size="h2">Avez-vous besoin d'aide ?</Title>
                    </CardHeader>
                    {!posted.completed && <CardBody>
                        <HList w="100%">
                            <Button
                                disabled={posted.syncing}
                                children="OUI"
                                loading={posted.syncing}
                                onClick={() => postForm("KO")}
                                size="wide"
                            />
                            <Button
                                disabled={posted.syncing}
                                children="NON"
                                loading={posted.syncing}
                                onClick={() => postForm("OK")}
                                size="wide"
                                type="outline"
                            />
                        </HList>
                    </CardBody>}
                    {posted.completed && <CardBody>
                        Merci pour votre réponse !
                    </CardBody>}
                </VList>
            </Card>
        </Wrapper>
    )
}

export default Form
